@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 5%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 5%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 5%;

    --primary: 243 75% 59%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96%;
    --secondary-foreground: 0 0% 5%;

    --muted: 0 0% 96%;
    --muted-foreground: 0 0% 45%;

    --accent: 0 0% 96%;
    --accent-foreground: 0 0% 5%;

    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 92%;
    --input: 0 0% 85%;
    --ring: 0 0% 76%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 3% 6%;
    --foreground: 0 0% 98%;

    --card: 270 6% 7%;
    --card-foreground: 0 0% 98%;

    --popover: 270 6% 7%;
    --popover-foreground: 0 0% 98%;

    --primary: 243 75% 59%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 5% 15%;
    --secondary-foreground: 0 0% 98%;

    --muted: 250 5% 22%;
    --muted-foreground: 240 5% 49%;

    --accent: 244 45% 14%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5% 12%;
    --input: 240 4% 18%;
    --ring: 244 75% 40%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/static/fonts/Anton-Regular.ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
}

/* TODO: Deprecate and remove these classes */
.default-input {
  @apply w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-gray-900 focus:outline-none;
}

.primary-button-solid {
  @apply inline-flex items-center justify-center gap-x-2 rounded-md px-4 py-2;
  @apply text-base font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2;
  @apply border border-indigo-600 bg-indigo-600 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline-indigo-600;
  @apply disabled:opacity-50;
}

.primary-button-outline {
  @apply inline-flex items-center justify-center gap-x-2 rounded-md px-4 py-2;
  @apply text-base font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2;
  @apply border border-indigo-600 bg-white text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline-indigo-600;
  @apply disabled:opacity-50;
}
